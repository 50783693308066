import {
  Button,
  Card,
  Stack,
  StackDivider,
  Checkbox,
  Tag,
  Box,
  Select,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Icon,
  CardHeader,
  CardFooter,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { InfoOutlineIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AbsenceTypeHelper } from "../../../dal/absence_type_helper";
import { SchoolPeriodHelper } from "../../../dal/school_period_helper";
import { SchoolDateHelper } from "../../../dal/school_date_helper";
import { SchoolOtherSettingsHelper } from "../../../dal/school_other_settings_helper";
import {
  AbsenceLeaveRequestItemInfo,
  AbsenceRecordInfo,
  AbsenceType,
  LeaveApplicationRecord,
  SchoolDate,
  SchoolOtherSettings,
  SemesterInfo,
  SemesterStrInfo,
} from "../../../dal/value_object";
import dayjs from "dayjs";
import { DateObject } from "react-multi-date-picker";
import { DSAAbsenceHelper } from "../../../dal/dsa_absence_helper";
import { useUserContext } from "../../../components/auth/user-validation";
import { LeaveApplicationHelper } from "../../../dal/leave_application_helper";
import { Util } from "../../../dal/util";
import { DialogBox } from "../../../components/DialogBox";
import { LoadingDialog } from "../../../components/LoadingDialog";
import { PdfZoomModal } from "../../../components/Modals/PdfZoomModal";
import { useTranslation } from "react-i18next";

/**  缺曠請假 */
export const Absence = () => {
  console.log(' reRender Absence component.')
  const { userInfo, currentSemester } = useUserContext();

  // ====== 學校設定值 =====
  const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>([]); // 學校在本學期允許請假的假別
  const [allowedPeriods, setAllowedPeriods] = useState<string[]>([]); // 學校在本學期允許請假的節次
  const [schoolDates, setSchoolDates] = useState<SchoolDate[]>([]); // 學校在本學期定義的上課日
  const [schoolOtherSettings, setSchoolOtherSettings] =
    useState<SchoolOtherSettings | null>(null); // 學校其他設定

  const [selectedSemester, setSelectedSemester] = useState<string>(); // 目前選擇的學年期，ex: 112-1
  const [semesters, setSemesters] = useState<SemesterInfo[]>([]); // 此學生有缺曠的學年期
  const [absenceRecords, setAbsenceRecords] = useState<AbsenceRecordInfo[]>([]); // 目前學生在指定學年期的缺曠紀錄
  const [leaveApplications, setLeaveApplications] = useState<any[]>([]); // 目前學生在指定學年期的請假紀錄
  const [targetRecords, setTargetRecords] = useState<AbsenceLeaveRequestItemInfo[]>([]);

  const [isSettingReady, setIsSettingReady] = useState<boolean>(false); // 學校是否已經完成設定

  // ===== 紀錄使用者操作 =====
  const [ isContinousDates, setIsContinousDates] = useState<boolean>(true);
  const [isDialogBoxOpened, setIsDialogBoxOpened] = useState<boolean>(false); // 是否開啟提示的對話方塊

  const [showLoading, setShowLoading] = useState<boolean>(false);
  
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");

  useEffect(() => {
    loadData();
  }, []);

  /**
   *  讀取資料，共需呼叫 七個資料。
   */
  const loadData = async () => {
    setShowLoading(true);

    const setting = await SchoolOtherSettingsHelper.getSchoolOtherSettings();
    setSchoolOtherSettings(setting);

    const p1 = AbsenceTypeHelper.getAllowedAbsenceTypes(); // 學校允許請假的假別
    const p2 = SchoolPeriodHelper.getAllowedPeriods(); // 取得學校允許請假的節次
    const p3 = SchoolDateHelper.getDatesBySemester(undefined); // 取得學校在目前學期的上課日。
    const p4 = SchoolOtherSettingsHelper.get(); // 取得學校允許申請曠課請假的日期限制
    const p5 = DSAAbsenceHelper.getAbsenceSemesters(); // 取得學生有缺曠的學期

    // 取得學生在目前學年期的缺曠紀錄
    const p6 = loadAbsenceRecords(
      currentSemester.schoolYear,
      currentSemester.semester,
      setting
    );

    // 取得學生在指定學期的請假紀錄
    const p7 = loadLeaveApplications(
      currentSemester.schoolYear,
      currentSemester.semester
    );

    Promise.all([p1, p2, p3, p4, p5, p6, p7]).then((values) => {
      console.log({ values });
      setAbsenceTypes(values[0]);
      setAllowedPeriods(values[1]);
      setSchoolDates(values[2]);
      // setSchoolOtherSettings(values[3]);
      const sems = values[4].sort((semX: SemesterInfo, semY: SemesterInfo) => {
        if (semX.SchoolYear > semY.SchoolYear) {
          return -1;
        } else if (semX.SchoolYear < semY.SchoolYear) {
          return 1;
        } else {
          if (semX.Semester > semY.Semester) {
            return -1;
          } else if (semX.Semester < semY.Semester) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      setSemesters(sems);

      parseData(values[5], values[6], values[3]);

      // console.log({ settings: values[3]})
      // 檢查學校需設定資料是否齊全
      let isReady =
        values[0].length > 0 &&
        values[1].length > 0 &&
        values[2].length > 0 &&
        (!!values[3] ? !!values[3].id : false);
      setIsSettingReady(isReady);
      setIsDialogBoxOpened(!isReady);
      setShowLoading(false);
    });


  };

  /** 取得學生在指定學年期在校務系統中的缺曠紀錄 */
  const loadAbsenceRecords = async (
    schoolYear: number | undefined,
    semester: number | undefined,
    setting: SchoolOtherSettings | null
  ) => {
    const absRecords = await DSAAbsenceHelper.getAbsenceRecords(
      schoolYear,
      semester
    ); // 取得學生在目前學年期的缺曠紀錄

    // 篩選有曠課的日期節次
    const absName:string[] = setting?.absence_name?.split(',') || ['曠'];  // 預設是'曠' 這個字
    // 比對出曠課的節次
    
    /*const result = absRecords.map( absRec => {
      absRec.AbsenceDetail = absRec.AbsenceDetail.filter( absDetail => 
         (absDetail.AbsenceType.indexOf(absName) > -1))    
      return absRec ;
    }).filter( absRec => absRec.AbsenceDetail.length > 0);*/

    const result = absRecords
    .map(absRec => ({
      ...absRec,
      AbsenceDetail: absRec.AbsenceDetail.filter(absDetail => 
      absName.some(name => absDetail.AbsenceType===name)
    )
    }))
    .filter(absRec => absRec.AbsenceDetail.length > 0);

    setAbsenceRecords(result);
    return result;
  };

  /** 取得學生在指定學年期的請假紀錄 */
  const loadLeaveApplications = async (
    schoolYear: number | undefined,
    semester: number | undefined
  ) => {
    const apps =
      await LeaveApplicationHelper.getStudentHistoriesBySchoolYearAndSemester(
        schoolYear?.toString(),
        semester?.toString()
      );
    setLeaveApplications(apps);
    return apps;
  };

  /** 處理切換學年期 */
  const handleSelectSemester = async (e: any) => {
    console.log({ semester: e.target.value });
    const semText = e.target.value;
    const [schoolYear, semester] = semText.split("-");
    setSelectedSemester(e.target.value);
    const targetSemester = semesters.find(
      (sem) =>
        sem.SchoolYear.toString() === schoolYear &&
        sem.Semester.toString() === semester
    );
    if (!targetSemester) {
      return;
    }
    console.log({ semText, targetSemester });

    await loadDataBySemester(
      targetSemester.SchoolYear,
      targetSemester.Semester
    );

  };

  const loadDataBySemester = async (schoolYear: number, semester: number) => {

    // setShowLoading(true);

    // 取得學校在指定學期的上課日。
    const p1 = SchoolDateHelper.getDatesBySemester(schoolYear, semester);

    // 取得學生在目前學年期的缺曠紀錄
    const p2 = loadAbsenceRecords(schoolYear, semester, schoolOtherSettings);

    // 取得學生在指定學期的請假紀錄
    const p3 = loadLeaveApplications(schoolYear, semester);

    Promise.all([p1, p2, p3]).then((values) => {
      setSchoolDates(values[0]);
      parseData(values[1], values[2], schoolOtherSettings);

      // setShowLoading(false);
    });
  };

  /** 解析找出缺曠紀錄的狀態 */
  const parseData = (
    absRecords: AbsenceRecordInfo[],
    leaveAppRecords: LeaveApplicationRecord[],
    otherSettings: SchoolOtherSettings | null
  ) => {
    const tempAbsRecords: AbsenceLeaveRequestItemInfo[] = absRecords.sort( (x, y) => {
      if (x.OccurDate > y.OccurDate) { return 1 ;}
      if (x.OccurDate < y.OccurDate) { return -1 ;}
      return 0;
    }).map((absRec) => {
      const absDate = dayjs(absRec.OccurDate);
      // 1. 判斷是否申請中（判斷請假紀錄是否有該日期）
      const isInProgress =
        leaveAppRecords.filter(
          (lapp) =>
            (lapp.status === "progressing") && (
              lapp.leaveDateAndPeriod.filter(
                (lvDP) => dayjs(lvDP.d).format('YYYY/MM/DD') === absDate.format("YYYY/MM/DD")
              ).length > 0
            )
        ).length > 0;
      // console.log ({ absRec,  isInProgress, leaveAppRecords});
      let isExpired = false;
      if (!isInProgress) {
        // 2. 如果不是申請中，則再判斷是否逾期
        if (otherSettings?.date_range) {
          
          if (otherSettings?.date_range === -1) {  // 當學期
            isExpired = (
              absRec.SchoolYear !== currentSemester.schoolYear ||
              absRec.Semester !== currentSemester.semester);
          } else {  // 就是指定日期了 => 判斷曠課日加上指定日期，不能早於今天。
            const targetDate = absDate.add( otherSettings.date_range , 'days');
            isExpired = !targetDate.isAfter(dayjs());
            // console.log( { absDate: absDate.toDate(),  targetDate: targetDate.toDate(), isExpired})
          }
        }
      }

      const newObj: AbsenceLeaveRequestItemInfo = { rawAbsRec: absRec, checked: false, isInProgress, isExpired};
      return newObj ;
    });

    const result = tempAbsRecords.sort(( x:any, y: any) => {
      if (x.rawAbsRec.OccurDate > y.rawAbsRec.OccurDate) {
        return 1 ;
      } else if (x.rawAbsRec.OccurDate > y.rawAbsRec.OccurDate) {
        return -1 ;
      } else {
        return 0;
      }
    });

    setTargetRecords(result);
    console.log( { result });
  };

  const addLeaveApplication = (e: any) => {
    navigate("add", {
      state: {
        absRecords: targetRecords.filter( tr => tr.checked),
        absenceTypes,
        schoolOtherSettings
      }

    });
  };

  /** 處理使用者勾選某一筆缺曠時 */
  const handleAbsRecordChecked = (checked: boolean, targetRec: AbsenceLeaveRequestItemInfo) => {
    console.log({ checked, targetRec});
    targetRec.checked = checked ;
    const otherRecs = targetRecords.filter( rec => rec.rawAbsRec.OccurDate !== targetRec.rawAbsRec.OccurDate);
    const tempData = [ ...otherRecs, targetRec ].sort(( x:any, y: any) => {
      if (x.rawAbsRec.OccurDate > y.rawAbsRec.OccurDate) {
        return 1 ;
      } else if (x.rawAbsRec.OccurDate < y.rawAbsRec.OccurDate) {
        return -1 ;
      } else {
        return 0;
      }
    });
     // 判斷是否是連續日期
     const targetAbsDates = tempData.filter( td=> td.checked).map( td=> td.rawAbsRec.OccurDate);
     const isContinue = Util.isContinousDates(targetAbsDates, schoolDates);
     console.log({ isContinue })
     setIsContinousDates(isContinue);

     // 如果不是，則
    setTargetRecords(tempData );
    // console.log( { tempData })
    // setTargetRecords( [ ...targetRecords] );
  }

  return (
    <>
      <div className="max-w-md h-screen bg-slate-100 py-4 px-4 sm:px-6">
        {/* Navigation Header */}
        <div className="grid grid-cols-3 items-center gap-3">
          <Button
            colorScheme="blue"
            variant="ghost"
            size="md"
            className="!p-0 w-fit"
            onClick={() => navigate('/student')}
            leftIcon={
              <Icon w={6} h={6} color="blue.500" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                  <path
                    fill="currentColor"
                    d="M8.293 12.707a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414z"
                  />
                </g>
              </Icon>
            }
          >
            {/* <span>返回主頁</span> */}
          </Button>
          <div className="text-center text-lg font-semibold whitespace-nowrap">{t("app.absence_records")}</div>
        </div>

        {/* 學年期 與請假規定 */}
        <div className="flex flex-wrap justify-between items-center pb-4">
          <div className="flex gap-3">
            <Select
              variant="flushed"
              placeholder=""
              value={selectedSemester}
              onChange={handleSelectSemester}
              className="!w-auto"
            >
              {semesters &&
                semesters.map((sem) => (
                  <option
                    key={`${sem.SchoolYear}-${sem.Semester}`}
                    value={`${sem.SchoolYear}-${sem.Semester}`}
                  >
                    {sem.SchoolYear}{i18n.language === "en" ? "-Semester " : " 學年度第" }{sem.Semester}{i18n.language === "en" ? "" : "學期" }
                  </option>
                ))}
            </Select>
          </div>
         {/*} <div onClick={()=>setIsPdfModalOpen(true)} className="cursor-pointer">          
            <InfoOutlineIcon />
              <span className="ms-1 align-middle">請假規定</span>
                </div>*/}
            <ChakraLink href={schoolOtherSettings?.leave_rule+'?__target=InAppBrowser'} isExternal className="cursor-pointer" style={{ textDecoration: 'none' }}>
              <InfoOutlineIcon />
              <span className="ms-2 align-middle">{t("app.rules_of_leave")}</span>
            </ChakraLink> 
        </div>

        <div className="flex flex-wrap justify-between items-center gap-1 mb-4">
          <div>
            {i18n.language === "en" ? "" : t("app.selected")} <span>{targetRecords.filter( tr => tr.checked ).length}</span> {t("app.day")} 
            <span> {targetRecords.filter( tr => tr.checked ).reduce( (accVal, current) => ( accVal + current.rawAbsRec.AbsenceDetail.length), 0)}</span> {t("app.session")} {i18n.language === "en" ? t("app.selected") : ""}
          </div>
          {
            (schoolDates.length === 0) && (
              <div className=" text-red-500 font-bold">{t("app.no_class_days_set")}</div>
            )
          }

          { !isContinousDates && (
            <div className=" text-red-500 font-bold">{t("app.dates_not_consecutive")}</div>
          )}
          
          <Button
            isDisabled={ schoolDates.length === 0 || targetRecords.filter( tr => tr.checked ).length === 0 ||  !isContinousDates}
            colorScheme="twitter"
            className="!min-w-[128px] ms-auto"
            onClick={addLeaveApplication}
          >
            {t("app.absent_leave_s")}
          </Button>
        </div>

        <Card className="!rounded-2xl">
          <CardHeader className="!p-2" />
          <Stack
            divider={<StackDivider />}
            spacing="2"
            className="h-stud overflow-auto"
          >
            { targetRecords && targetRecords.map( rec => (
              
              <Box className="p-3" key={rec.rawAbsRec.OccurDate}>
                <div className="flex items-baseline gap-x-2">
                  <div className="flex-shrink-0">
                    { rec.isInProgress && (
                      <Tag as={'div'} size="sm" className="bg-ing !text-white whitespace-nowrap">{t("app.pending")}</Tag>
                    )}

                    { rec.isExpired && (
                      <Tag as={'div'} size="sm" className="bg-over !text-white whitespace-nowrap">{t("app.overdue")}</Tag>
                    )}
                    
                    { (!rec.isInProgress && !rec.isExpired) && (
                      <Checkbox size="lg" colorScheme="twitter" onChange={(e) => {handleAbsRecordChecked(e.target.checked, rec)  }} checked={rec.checked} />
                    )}
                  </div>
                  <div className="flex-shrink-0 text-lg">{ dayjs(rec.rawAbsRec.OccurDate).year() }<div className="flex-shrink-0 text-base">{dayjs(rec.rawAbsRec.OccurDate).format('MM/DD')}</div></div>
                  <div className="text-xl font-semibold text-start">
                  <div className="">{t("app.total")} <span>{rec.rawAbsRec.AbsenceDetail.length}</span> {t("app.session")}</div>
                  <div className="text-justify font-normal text-base">
                  {t("app.session_l")}：{rec.rawAbsRec.AbsenceDetail.map( (absDetail: any) => (absDetail.Period)).join(', ') }
                  </div>
                  </div>
                </div>
              </Box>
            )
            )}
            
          </Stack>
          {/* No data */}
          {/* <div className='flex flex-col justify-center items-center h-[500px]'>
                  <img src="/assets/img/nodata.png" alt="" className='w-32 mx-auto mb-4' />
                  <div>沒有資料</div>
                </div> */}
          <CardFooter className="!p-2" />
        </Card>
      </div>
     {/* 
      <Modal
        size="xl"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />

          <ModalBody className="!px-3">
            <object
              data="/assets/pdf/rule.pdf"
              type="application/pdf"
              title="請假規定"
              width="100%"
              className="min-h-[600px]"
            />
            </ModalBody> 

          <ModalFooter></ModalFooter>
        </ModalContent>
              </Modal>  */}
      {/* 提醒使用者學校資料尚未設定完全的視窗 */}
      <DialogBox
        isOpen={isDialogBoxOpened}
        title="錯誤"
        msg="當學期未完成請假設定，目前無法請假！請系統管理員檢查上課日、節次及請假類別設定。"
        closeButtonText="取消"
        showCancelButton={false}
        okButtonText="返回"
        onOK={() => navigate(-1)}
      />
      {/* 顯示載入中的視窗 */}
      <PdfZoomModal
        isModalOpen={isPdfModalOpen}
        onModalClose={()=>setIsPdfModalOpen(false)}
        pdfUrl="../assets/pdf/rule.pdf"
        title="請假單"
      />
      <LoadingDialog isOpen={showLoading} />
    </>
  );
};
