import React from "react";
import "./App.css";
import { ChakraProvider, extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { useRouter } from "./router";

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
}
const theme = extendTheme({ config })

function App() {
  const router = useRouter();
  return (
    <>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </>
  );
}

export default App;
